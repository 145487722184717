.terms-container {
  display: flex;
  justify-content: center;
  padding: 40px 20px;
  min-height: 100vh;
  background-color: #f9f9f9;
}

.terms-content {
  max-width: 1000px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 40px;
}

.terms-header {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
}

.terms-logo {
  max-width: 100px;
  height: auto;
}

.terms-body {
  color: #333;
  font-size: 16px;
  line-height: 1.6;
}

/* Style overrides for the loaded HTML content */
.terms-body h1,
.terms-body h2,
.terms-body h3 {
  /* color: #016dea; */
  margin-top: 1.5em;
  margin-bottom: 0.75em;
}

.terms-body p {
  margin-bottom: 1em;
}

.terms-body ul,
.terms-body ol {
  margin-bottom: 1em;
  padding-left: 2em;
}

.terms-body li {
  margin-bottom: 0.5em;
}

/* New classes for terms popup */
.terms-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1999;
}

.terms-popup-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  z-index: 2000;
}

.terms-popup-content {
  max-width: 800px;
  width: 100%;
  max-height: 80vh;
  overflow-y: auto;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  padding: 30px;
}

.terms-agree-button {
  display: block;
  width: 100%;
  padding: 12px;
  margin-top: 20px;
  background-color: #016dea;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.terms-agree-button:hover:not(:disabled) {
  background-color: #0056b3;
}

.terms-agree-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .terms-content {
    padding: 20px;
  }

  .terms-body {
    font-size: 14px;
  }
  
  .terms-popup-content {
    max-height: 70vh;
    padding: 20px;
  }
}

@media (max-width: 480px) {
  .terms-container {
    padding: 20px 10px;
  }

  .terms-content {
    padding: 15px;
  }
  
  .terms-popup-content {
    max-height: 60vh;
    padding: 15px;
  }
} 